import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Loader from "../../Components/Loader/Loader";
import Layout from "../../Components/Layout/Layout";
import { axiosInstance } from "../../Axios/https";


/* eslint-disable jsx-a11y/anchor-is-valid */
function EditProfile() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const user = JSON.parse(localStorage.getItem("data"));

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("First name is required"),
        email: Yup.string().required("Email is required"),
    });

    const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
        useFormik({
            initialValues: {
                name: user?.user?.name,
                email: user?.user?.email,
            },
            validationSchema,
            onSubmit: (values) => {
                setLoader(true);
                const formData = new FormData();
                formData.append("name", values.name);
                formData.append("email", values.email);
                axiosInstance
                    .post("profile-update", formData)
                    .then((res) => {
                        console.log(res?.data);
                        toast.success(res?.data?.message);
                        navigate("/viewprofile");
                        setLoader(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(err.response.data.message);
                        setLoader(false);
                    });
            },
        });

    return (
        <>
            <Layout>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto py-4">
                            <div className="central-meta">
                                <div>
                                    <h2 className="f-title mb-0">
                                        <i className="ti-lock" />
                                        Edit Profile
                                    </h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input id="input"
                                                type="text"
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                            />
                                            <label className="control-label" htmlFor="input">
                                                First Name
                                            </label>
                                            <i className="mtrl-select" />
                                            <span className="error">{touched.name && errors.name}</span>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                name="email"
                                                onChange={handleChange}
                                                value={values.email}
                                                onBlur={handleBlur}
                                            />
                                            <label className="control-label" htmlFor="input">
                                                Email
                                            </label>
                                            <i className="mtrl-select" />
                                            <span className="error">{touched.email && errors.email}</span>
                                        </div>

                                        <div className="submit-btns">
                                            <button type="submit" className="mtr-btn signup" style={{ background: '#eb0a8c', width: '160px' }}>
                                                {
                                                    loader ? <Loader /> :
                                                        <>
                                                            <span> Edit Profile</span>
                                                        </>
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default EditProfile