import React from 'react'
import Signup from '../../Components/Auth/Signup'

function SignupPage () {
  return (
    <>
      <Signup />
    </>
  )
}

export default SignupPage


