import React from 'react'
import Contact from '../Components/Contact/Contact'
import Layout from '../Components/Layout/Layout'


function ContactPage  () {
  return (
    <Layout>
       <Contact />
    </Layout>
  )
}

export default ContactPage
