import * as Yup from "yup";
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { axiosInstance } from "../../Axios/https";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import './login.css'
import Loader from "../Loader/Loader";

function Signup() {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("firstname is required"),
    email: Yup.string().required("email is required"),
    password: Yup.string().required("password is required"),
  });


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    console.log(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setImageUrl("");
    document.getElementById("imageInput").value = "";
  };

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        password: "",
      },
      validationSchema,
      onSubmit: (values) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append("image", file);
        axiosInstance
          .post("register", formData)
          .then((res) => {
            console.log(res);
            toast.success(res?.data?.message);
            navigate("/login");
            setLoader(false);
          })
          .catch((err) => {
            console.log(err);
            toast.error(err?.response?.data?.message);
            setLoader(false);
          });
      },
    });


  return (
    <>
      <section style={{ background: '#eb0a8c', height: 'auto' }}>
        <div className="gap mt-5 overlap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="contact-form contct-info shadow-none rounded">
                    <div className="cnt-title">
                      <h2 className="text-center fw-bold">Signup</h2>
                      <i>
                        <img src="images/envelop.png" alt="" />
                      </i>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group w-100">
                        <input
                          type="text"
                          name="name"
                          placeholder="First Name"
                          onChange={handleChange}
                          value={values.name}
                          onBlur={handleBlur}
                        />
                        <label className="control-label" htmlFor="input">
                          First Name
                        </label>
                        <i className="mtrl-select" />
                        <span className="error">{touched.name && errors.name}</span>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          value={values.email}
                          onBlur={handleBlur}
                        />
                        <label className="control-label" htmlFor="input">
                          Email
                        </label>
                        <i className="mtrl-select" />
                        <span className="error">{touched.email && errors.email}</span>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          onChange={handleChange}
                          value={values.password}
                          onBlur={handleBlur}
                        />
                        <label className="control-label" htmlFor="input">
                          Password
                        </label>
                        <i className="mtrl-select" />
                        <span className="error">
                          {touched.password && errors.password}
                        </span>
                      </div>


                      <div className="file-input d-flex justify-content-center">
                        <input
                          type="file"
                          name="file-input"
                          id="file-input"
                          className="file-input__input"
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                        <label className="file-input__label" htmlFor="file-input">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="upload"
                            className="svg-inline--fa fa-upload fa-w-16"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                            ></path>
                          </svg>
                          <span>Upload Profile Picture</span>
                        </label>
                      </div>
                      <div>
                        {imageUrl && (
                          <div className="flex-auto">
                            <img id="imageInput" src={imageUrl} alt="Preview" width={"200px"} />
                            <button
                              style={{ marginTop: "10px" }}
                              onClick={handleImageRemove}
                            >
                              Remove Image
                            </button>
                          </div>
                        )}
                      </div>


                      <Link to={"/login"}>
                        <span className="cursor-pointer" style={{ color: '#088DCD', fontSize: '16px' }}>Signin your account?</span>
                      </Link>
                      <div className="submit-btns">
                        <button className="mtr-btn signup" style={{ background: '#eb0a8c' , width:'150px' }} type="submit">
                          {
                            loader ? <Loader /> :
                              <>
                                <span className="text-white mx-2">Signup</span><i className="fa fa-paper-plane" />
                              </>
                          }
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Signup


