/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { RiTiktokFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { TiSocialFacebook } from "react-icons/ti";
import { FaXTwitter } from "react-icons/fa6";

function Contact() {
  return (
    <div>
      <>
        <section>
          <div className="gap no-top">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="g-map">
                    <div id="map-canvas" />
                    <div className="map-meta">
                      {/* <h1 className='fs-1'>get in touch</h1> */}
                      <p className='fw-bolder'>
                        IF YOU THINK YOU'D BE A MATCH TO APPEAR AS A GUEST ON PINK CONFINEMENT OR IF YOU HAVE ANY INQUIRIES OR FEEDBACK.FEEL FREE TO CONTACT US AT TEAMPINK@PINKCONFINEMENT.CLUB WE'RE EXCITED TO HERE FROM YOU
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="gap no-top overlap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="contct-info">
                    <div className="contact-form">
                      <div className="cnt-title">
                        <span>Send us a message</span>
                        <i>
                          <img src="images/envelop.png" alt="" />
                        </i>
                      </div>
                      <form method="post">
                        <div className="form-group">
                          <input type="text" id="input" required="required" />
                          <label className="control-label" htmlFor="input">
                            Name
                          </label>
                          <i className="mtrl-select" />
                        </div>
                        <div className="form-group">
                          <input type="text" required="required" />
                          <label className="control-label" htmlFor="input">
                            Email
                          </label>
                          <i className="mtrl-select" />
                        </div>
                        <div className="form-group w-100">
                          <textarea
                            rows={4}
                            cols={30}
                            id="textarea"
                            required="required"
                            defaultValue={""}
                          />
                          <label className="control-label" htmlFor="textarea">
                            Message
                          </label>
                          <i className="mtrl-select" />
                        </div>
                        <div className="submit-btns">
                          <button className="mtr-btn signup" type="button">
                            <i className="fa fa-paper-plane" />
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="cntct-adres">
                      <h3>contact info</h3>
                      <ul>
                        <li>
                          <i className="ti-location-pin" />
                          <span>Address: P.O. Box 5133
                            Columbus, GA 31906</span>
                        </li>

                        <li>
                          <i className="fa fa-envelope-o" />
                          <span>
                            <a
                              href="https://www.wpkixx.com/cdn-cgi/l/email-protection"
                              className="__cf_email__ text-light"
                              data-cfemail="c5acaba3aa85bcaab0b7a8a4aca9eba6aaa8"
                            >
                              TeamPink@pinconfinement.club
                            </a>
                          </span>
                        </li>
                      </ul>
                      <ul className="social-media">
                        <li>
                          <a href="https://www.facebook.com/ConfinementPink?mibextid=ibOpuV" title="">
                            <TiSocialFacebook color='white' />

                          </a>
                        </li>
                        <li>
                          <a href="https://www.tiktok.com/@pinkconfinement?_t=8kqLCypkHGU&_r=1" title="">
                            <RiTiktokFill color='white' />{" "}

                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/pinkconfinement?igsh=MTl2aWNsejYxMzRpaw==" title="">
                            <FaInstagram color='white' />
                          </a>
                        </li>
                        <li>
                          <a href="https://x.com/pinkconfinement?s=21" title="">
                            <FaXTwitter color='white' />
                          </a>
                        </li>
                      </ul>
                      <h1 className="bg-cntct">Winku</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>

    </div>
  )
}

export default Contact
