import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import ContactPage from "./Pages/ConatctPage";
import LoginPage from "./Pages/Auth/LoginPage";
import SignupPage from "./Pages/Auth/SignupPage";
import SubcriptionPage from "./Pages/SubcriptionPage";
import ForgotPassword from "./Pages/Auth/FotgotPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";
import ViewProfile from "./Pages/Auth/ViewProfile";
import ChangePassword from "./Pages/Auth/ChangePassword";
import EditProfile from "./Pages/Auth/EditProfile";
import Thanks from "./Components/Thanks/Thanks";
import Reject from "./Components/Thanks/Reject";
import AboutPage from "./Pages/AboutPage";

function App() {
  return (
    <>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/subcription" element={<SubcriptionPage />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/viewprofile" element={<ViewProfile />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/editprofile" element={<EditProfile />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="/reject" element={<Reject />} />
          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
