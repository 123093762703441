/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import './thanks.css'

function Thanks() {
    return (
        <>
            <div>
                <div className='reject'>
                    <h1>Thank you !</h1>
                    <p>Thanks you for your Donation</p>
                    <Link class="button" to={"/"}>
                        <i class="icon-home"></i> Go to Home.
                    </Link>
                </div>
            </div>

        </>
    )
}

export default Thanks

