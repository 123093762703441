/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { RiTiktokFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { TiSocialFacebook } from "react-icons/ti";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3  mx-auto">
              <div className="widget">
                <div className="foot-logo">
                  <div className="logo">
                    <a href="/" title="">
                      <img src="/assets/images/logo.png" alt="" width="180" />
                    </a>
                  </div>
                  <p className='fs-6 fw-bolder'>
                   REAL RAW & UNCUT 
                  </p>
                </div>
                {/* <ul className="location">
                  <li>
                    <i className="ti-map-alt" />
                    <p>Address: P.O. Box 5133
                      Columbus, GA 31906</p>
                  </li>

                </ul> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="widget">
                <div className="widget-title">
                  <h4>follow</h4>
                </div>
                <ul className="list-style">
                  <li>
                    <TiSocialFacebook />
                    <a href="https://www.facebook.com/ConfinementPink?mibextid=ibOpuV" title="">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <RiTiktokFill />{" "}
                    <a href="https://www.tiktok.com/@pinkconfinement?_t=8kqLCypkHGU&_r=1" title="">
                      TikTok
                    </a>
                  </li>
                  <li>
                    <FaInstagram />{" "}
                    <a href="https://www.instagram.com/pinkconfinement?igsh=MTl2aWNsejYxMzRpaw==" title="">
                      Instagram
                    </a>
                  </li>
                  <li>
                    <FaXTwitter />{" "}
                    <a href="https://x.com/pinkconfinement?s=21" title="">
                      Twitter
                    </a>
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="widget">
                <div className="widget-title">
                  <h4>Navigate</h4>
                </div>
                <ul className="list-style">
                  <li>
                    <Link to={"/about"} title="">
                      about us
                    </Link>
                  </li>
                  <li>
                    <Link to={"/contact"} title="">
                      contact us
                    </Link>
                  </li>
                  <li>
                    <a href="#" title="">
                      terms &amp; Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
