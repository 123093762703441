import * as Yup from "yup";
import { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Loader from "../../Components/Loader/Loader";
import { axiosInstance } from "../../Axios/https";


function ForgotPassword () {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
  
    const validationSchema = Yup.object().shape({
      email: Yup.string().required("email is required"),
    });
  
    const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
      useFormik({
        initialValues: {
          email: "",
        },
        validationSchema,
        onSubmit: (values) => {
          setLoader(true);
          const formData = new FormData();
          formData.append("email", values.email);
          axiosInstance
            .post("forgot-password", formData)
            .then((res) => {
              console.log(res?.data?.data);
              toast.success(res?.data?.message);
              navigate("/resetpassword" , {state: {email: values.email}});
              setLoader(false);
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.response.data.message);
              setLoader(false);
            });
        },
      });
   return (
    <>
      <section style={{ background: '#eb0a8c', height: '100vh' }} className="d-flex justify-content-center align-items-center">
        <div className="gap mt-5 overlap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="contact-form contct-info shadow-none rounded">
                    <div className="cnt-title">
                      <h2 className="text-center fw-bold">Forgot Password</h2>
                      <i>
                        <img src="images/envelop.png" alt="" />
                      </i>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group w-100">
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter your Email"
                          onChange={handleChange}
                          value={values.email}
                          onBlur={handleBlur}
                        />
                        <i className="mtrl-select" />
                        <span className="error">{touched.email && errors.email}</span>
                      </div>
                      <div className="submit-btns">
                        <button type="submit" className="mtr-btn signup" style={{ background: '#eb0a8c' , width:'220px' }}>
                          {
                            loader ? <Loader /> :
                              <>
                                <span className="text-white mx-2">Forgot Password</span><i className="fa fa-paper-plane" />
                              </>
                          }
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
   )
}
export default ForgotPassword


